import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Form, CardFooter, Container } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import BreadCrumb from '../../components/elements/BreadCrumb'

import Modal from 'react-bootstrap/Modal';
import AlertCustom from '../../components/elements/AlertCustom'

import CountryList from '../../data/CountryList'

const baseURL = process.env.REACT_APP_ENDPOINT

const TracksList = () => {

    //Define 
    let tokenStr = localStorage.getItem('access_token')
    const navigate = useNavigate()
    // const detailsId = useParams()
    const prevStage = useLocation()
    const detailsId = prevStage.state || 0
    let currentEmail = localStorage.getItem('email')
    let currentRole = localStorage.getItem('role');


    const [alertData, setAlertData] = useState({
        'alertShow': false,
        'alertVariant': null,
        'alertMessage': null
    })
    const [data, setData] = useState({
        "event_name": "",
        "acronym_title": "",
    })
    const [allTracks, setAllTracks] = useState([])

    const [showReviewerModal, setShowReviewerModal] = useState(false);
    const [showAutoAssignModal, setShowAutoAssignModal] = useState(false);
    const [searchText, setSearchText] = useState('')
    const [userList, setUserList] = useState([])
    const [userTempList, setUserTempList] = useState([])
    const [manageReviewerTrackId, setManageReviewerTrackId] = useState(null)
    const [trackId, setTrackId] = useState(null)
    const [autoAssignModalHeader, setAutoAssignModalHeader] = useState('')
    const [singleTrackReviewer, setSingleTrackReviewer] = useState([])
    const [autoAssignNo, setAutoAssignNo] = useState({
        number_of_reviewers_per_paper: ''
    })
    const [autoAssignData, setAutoAssignData] = useState({
        total_paper_count: '',
        total_tpc_member_count: '',
        no_of_reviewer_per_paper: ''
    })
    const [checkAutoAssignSubmit, setCheckAutoAssignSubmit] = useState(0)

    const [reviewerAddManual, setReviewerAddManual] = useState({
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        affiliation: ''
    })


    //Action

    useEffect(() => {
        if (alertData.alertShow) {
            setTimeout(() => {
                setAlertData({
                    'alertShow': false,
                    'alertVariant': '',
                    'alertMessage': null
                })
            }, 7000)
        }
    }, [alertData])

    useEffect(() => {

        if (detailsId.id > 0) {
            Axios.get(`${baseURL}/site/conf/short/${detailsId.id}`)
                .then(res => {
                    setData(res.data.result[0])
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )


            Axios.get(`${baseURL}/search-users`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then(res => {
                    setUserTempList(res.data.result)
                })
                .catch(
                    error => {
                        if (error.response.status === 401) {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'danger',
                                'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 6000)
                        } else {
                            setAlertData({
                                'alertShow': true,
                                'alertVariant': 'warning',
                                'alertMessage': error.response.data
                            })
                        }
                    }
                )

            getAllTracks();
        }
    }, [detailsId])

    const getAllTracks = () => {
        Axios.get(`${baseURL}/track/all/${detailsId.id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setAllTracks(res.data.result)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }
    const getSingleTrack = (id) => {
        Axios.get(`${baseURL}/track//${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(res => {
                setSingleTrackReviewer(res.data.result.track_reviewer_data)
            })
            .catch(
                error => {
                    if (error.response.status === 401) {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': 'You are not authorized to access this content.You will be logged out. Please login again!'
                        })
                        setTimeout(() => {
                            navigate('/')
                        }, 6000)
                    } else {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'warning',
                            'alertMessage': error.response.data
                        })
                    }
                }
            )
    }

    const handleCloseReviewerModal = () => setShowReviewerModal(false);
    const handleCloseAutoAssignModal = () => setShowAutoAssignModal(false);
    const handleShowReviewerModal = (id) => {
        setManageReviewerTrackId(id)
        setTrackId(id)
        getSingleTrack(id)
        setReviewerAddManual({
            first_name: '',
            last_name: '',
            email: '',
            country: '',
            affiliation: ''
        })
        setShowReviewerModal(true)
    };
    const handleShowAutoAssignModal = (id, track_name) => {
        Axios.get(`${baseURL}/track/paper-rdetails-count/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                console.log(res.data.result.no_of_reviewer_per_paper)
                setAutoAssignData(res.data.result)
                setCheckAutoAssignSubmit(res.data.result.no_of_reviewer_per_paper)
                setAutoAssignNo({
                    number_of_reviewers_per_paper: res.data.result.no_of_reviewer_per_paper
                })
                // setAutoAssignNo((prev) => {
                //     return {
                //         ...prev, number_of_reviewers_per_paper: res.data.result.no_of_reviewer_per_paper
                //     }
                // })
                console.log(autoAssignNo.number_of_reviewers_per_paper)
            })
            .catch()
        setTrackId(id)
        setAutoAssignModalHeader(track_name)
        setShowAutoAssignModal(true)
    };

    const loadReviewer = (e) => {
        setSearchText(e.target.value)

        if (searchText.length > 1) {
            setUserList(userTempList.filter((item) => {
                item.email = item.email.toLocaleLowerCase()
                item.first_name = item.first_name.toLocaleLowerCase()
                item.last_name = item.last_name.toLocaleLowerCase()
                const compareString = searchText.toLocaleLowerCase()
                return item.email.startsWith(compareString) || item.first_name.startsWith(compareString) || item.last_name.startsWith(compareString)
            }))
        } else {
            setUserList([])
        }
    }

    const addReviewer = (track_id, reviewer_id) => {

        Axios.post(`${baseURL}/track/reviewer/${track_id}`, { 'reviewer_id': reviewer_id }, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                getSingleTrack(track_id)
                getAllTracks()

                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                }

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )

    }

    const handleChangeReviewerAddManual = (e) => {
        let name = e.target.name
        let value = e.target.value

        setReviewerAddManual((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleChangeAutoAssign = (e) => {
        let name = e.target.name
        let value = e.target.value

        setCheckAutoAssignSubmit(e.target.value)

        setAutoAssignNo((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const handleSubmitReviewerAddManual = (e) => {
        e.preventDefault()

        Axios.post(`${baseURL}/track/reviewer/${trackId}`, reviewerAddManual, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                getSingleTrack(trackId)
                getAllTracks()

                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                }

                setReviewerAddManual({
                    first_name: '',
                    last_name: '',
                    email: '',
                    country: '',
                    affiliation: ''
                })

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }
    const handleSubmitAutoAssign = (e) => {
        e.preventDefault()
        Axios.post(`${baseURL}/track/paper-reviewer-auto-assign/${trackId}`, autoAssignNo, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                getSingleTrack(trackId)
                getAllTracks()

                if (res.data.success === false) {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'warning',
                        'alertMessage': res.data.message
                    })
                } else {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                }
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    const deleteReveiewer = (track_id, id) => {
        const confirmation = window.confirm("Are you sure to delete this TPC Member?")
        if (confirmation) {
            Axios.delete(`${baseURL}/track/reviewer/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
                .then((res) => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'success',
                        'alertMessage': res.data.message
                    })
                    getSingleTrack(track_id)
                    getAllTracks()

                })
                .catch(
                    error => {
                        setAlertData({
                            'alertShow': true,
                            'alertVariant': 'danger',
                            'alertMessage': error.response.data
                        })
                    }
                )
        }
    }

    //SendMail
    const [sendMailData, setSendMailData] = useState({
        status: 0,
        sender_email: "",
        subject: "",
        message: ""
    })
    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const handleCloseSendMailModal = () => setShowSendMailModal(false);
    const handleShowSendMailModal = (confid, trackid) => {
        setConfId(confid)
        setTrackId(trackid)
        setShowSendMailModal(true)
    };
    
    const handleChangeSendMail = (e) => {
        let name = e.target.name
        let value = e.target.value

        setSendMailData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }    
    const handelSubmitSendMail = (e)=>{
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setSendMailData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
        Axios.post(`${baseURL}/track/tpc-reminder/${trackId}`, sendMailData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                setShowSendMailModal(false)
            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                    setShowSendMailModal(false)
                }
            )
    }


    const [tpcInviteData, setTpcInviteData] = useState('')
    const [confId, setConfId] = useState('')
    // const [trackId, setTrackId] = useState('')
    const [showTpcInviteModal, setShowTpcInviteModal] = useState(false);
    const handleCloseTpcInviteModal = () => setShowTpcInviteModal(false);
    const handleShowTpcInviteModal = (confid, trackid) => {
        setConfId(confid)
        setTrackId(trackid)
        setShowTpcInviteModal(true)
    };
    const handelSubmitInvitation = (e) => {
        e.preventDefault()

        const parts = tpcInviteData.split("\n")
        let formObj = []
        for (let i = 0; i < parts.length; i++) {
            let tempArr = parts[i].split(",")
            const obj = {
                "first_name": tempArr[0],
                "last_name": tempArr[1],
                "email": tempArr[2],
                "country": tempArr[3],
                "affiliation": tempArr[4]
            };
            formObj.push(obj);
        }
        let formData = {
            total_data: JSON.stringify(formObj)
        }

        Axios.post(`${baseURL}/track/tpc-track-wise-invitation/${confId}/${trackId}`, formData, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then((res) => {
                setAlertData({
                    'alertShow': true,
                    'alertVariant': 'success',
                    'alertMessage': res.data.message
                })
                setTpcInviteData('')
                setShowTpcInviteModal(false)

            })
            .catch(
                error => {
                    setAlertData({
                        'alertShow': true,
                        'alertVariant': 'danger',
                        'alertMessage': error.response.data
                    })
                }
            )
    }

    return (
        <>
            <AlertCustom data={alertData} />
            <BreadCrumb title={`${data.acronym_title} Tracks`} from='Conference' to='TPC Members' back={true} />
            <Container fluid>
                <div className='row gap-3'>
                    {
                        allTracks.map((item) => {
                            const {
                                id,
                                track_name,
                                track_short_name,
                                track_description,
                                track_chair_data,
                                track_reviewer_data
                            } = item

                            let trackChairChecker = () => {
                                return (
                                    track_chair_data.filter((item) => {
                                        return item.email === currentEmail
                                    })
                                )
                            }
                            // if (trackChairChecker().length > 0 || currentRole === 'ADMIN') {
                                return (
                                    <Card className='elevated col-6 gap3 track'>
                                        <CardBody className=''>
                                            <h4>{track_name}</h4>
                                            <div className='card-title h5 mt-2 mb-3'>{track_short_name}</div>
                                            <div className="grid-container">
                                                <div className='grid-item'><b>Description</b></div>
                                                <div className='grid-item maxh-10'>{track_description}</div>
                                                <div className='grid-item'><b>Track Chairs</b></div>
                                                <div className='grid-item'>
                                                    <ul className='maxh-7 mb-0 list-unstyled'>
                                                        {
                                                            track_chair_data.map((item) => {
                                                                const { first_name, last_name, email } = item
                                                                return (
                                                                    <li>
                                                                        {first_name} {last_name} / <small>{email}</small>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className='grid-item'><b>TPC Members</b></div>
                                                <div className='grid-item'>
                                                    <ul className='maxh-7 mb-0 list-unstyled'>
                                                        {
                                                            track_reviewer_data ?
                                                                track_reviewer_data.map((item) => {
                                                                    const {
                                                                        reviewer_first_name,
                                                                        reviewer_last_name,
                                                                        reviewer_email
                                                                    } = item
                                                                    return (
                                                                        <li>
                                                                            {reviewer_first_name} {reviewer_last_name} / <small>{reviewer_email}</small>
                                                                        </li>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <CardFooter className='border-top'>
                                            {
                                                // trackChairChecker().length > 0 || currentRole === 'ADMIN' ?
                                                <>
                                                    <button className='btn btn-light elevated btn-sm  my-2 me-2'
                                                        title='Manage TPC Members'
                                                        onClick={() => handleShowReviewerModal(id)}><span
                                                            className="material-icons ">manage_accounts</span> Manage TPC
                                                        Members
                                                    </button>
                                                    <Link className='btn btn-light elevated btn-sm my-2 me-2'
                                                        to={`/conference/trackPapers/${detailsId.id}/${id}`} title='All Papers'><span
                                                            className="material-icons">library_books</span> All Papers</Link>

                                                    
                                                    <button className='btn btn-light elevated btn-sm my-2 me-2' onClick={() => handleShowTpcInviteModal(detailsId.id, id)} title='Manage Chairs'><span className="material-icons ">forward_to_inbox</span> TPC Invitation</button>

                                                    {
                                                        currentRole === 'ADMIN' ? <><button className='btn btn-light elevated btn-sm my-2 me-2'
                                                        title='Auto Assign' onClick={() => handleShowAutoAssignModal(id, track_name)}><span
                                                            className="material-icons ">group_add</span> Auto Assign
                                                    </button><button className='btn btn-light elevated btn-sm my-2 me-2' onClick={() => handleShowSendMailModal(detailsId.id, id)} title='Send Mail'><span className="material-icons ">forward_to_inbox</span> Send Mail</button></> : ''
                                                    }
                                                </>
                                                // : ''
                                            }
                                        </CardFooter>
                                    </Card>
                                )
                            // }
                        })
                    }
                </div>
            </Container>
            {/* Manage Reviewer */}
            <Modal Modal show={showReviewerModal} onHide={handleCloseReviewerModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Reviewer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-5" style={{ minHeight: '20rem' }}>
                            <div className='mb-3 search'>
                                <p>Search here for users to add as TPC Members.</p>
                                <Form.Group className='mb-4' controlId="" label="Search TPC Members">
                                    <Form.Label>Search TPC Members</Form.Label>
                                    <Form.Control autoComplete='off' type="text" placeholder="Search TPC Members"
                                        name='reviewer_person' value={searchText} onChange={loadReviewer} />
                                </Form.Group>

                                <div className='suggestionList elevated'>
                                    {
                                        userList.map((item) => {
                                            const { id, first_name, last_name, email } = item
                                            return (
                                                <div className='d-flex justify-content-between py-1 px-3 listItem'>
                                                    <div>
                                                        <h5 className='mb-1'>{first_name} {last_name}</h5>
                                                        <span><small>{email}</small></span>
                                                    </div>
                                                    <div>

                                                        <button className='btn btn-sm btn-classic btn-primary'
                                                            onClick={() => addReviewer(manageReviewerTrackId, id)}>
                                                            <span className="material-icons">add</span> Add
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            <div className='text-center bg-fill-light py-2 mb-2 rounded'>
                                <h3>OR</h3>
                                <small>If not found you can add a TPC Members manually here.</small>
                            </div>
                            <div className='cozy'>
                                <Form onSubmit={handleSubmitReviewerAddManual}>
                                    <div className="row">
                                        <div className='col-lg-6 mb-1'>
                                            <label>First Name</label>
                                            <Form.Control type="text" placeholder="" name='first_name'
                                                value={reviewerAddManual.first_name}
                                                onChange={handleChangeReviewerAddManual} required />
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Last Name</label>
                                            <Form.Control type="text" placeholder="" name='last_name'
                                                value={reviewerAddManual.last_name}
                                                onChange={handleChangeReviewerAddManual} required />
                                        </div>
                                        <div className='col-lg-12 mb-1'>
                                            <label>Email</label>
                                            <Form.Control type="text" placeholder="" name='email'
                                                value={reviewerAddManual.email}
                                                onChange={handleChangeReviewerAddManual} required />
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Country</label>
                                            <Form.Select name='country' value={reviewerAddManual.country}
                                                onChange={handleChangeReviewerAddManual} required>
                                                <option>Select</option>
                                                {
                                                    CountryList.map((item, key) => {
                                                        return <option value={item} key={key}>{item}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                        <div className='col-lg-6 mb-1'>
                                            <label>Affiliation</label>
                                            <Form.Control type="text" placeholder="" name='affiliation'
                                                value={reviewerAddManual.affiliation}
                                                onChange={handleChangeReviewerAddManual} required />
                                        </div>
                                    </div>
                                    <div className='text-end'>
                                        <button className="btn btn-primary btn-classic" type='submit'><span
                                            className="material-icons">group_add</span> Add TPC Members
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-7 border-start">
                            {
                                <>
                                    <h3><strong>Added TPC Members</strong></h3>
                                    <ul className='list-unstyled maxh-20'>
                                        {
                                            singleTrackReviewer.map((item) => {
                                                const {
                                                    track_id,
                                                    id,
                                                    reviewer_first_name,
                                                    reviewer_last_name,
                                                    reviewer_email
                                                } = item
                                                return (
                                                    <div
                                                        className='d-flex justify-content-between align-items-center mb-1 listItem'>
                                                        <div>
                                                            <h5 className='m-0'>{reviewer_first_name} {reviewer_last_name} / <span><small>{reviewer_email}</small></span>
                                                            </h5>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-xs btn-classic btn-light text-danger elevated'
                                                                onClick={() => deleteReveiewer(track_id, id)}><span
                                                                    className="material-icons">delete_forever</span> Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal Modal show={showAutoAssignModal} onHide={handleCloseAutoAssignModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Auto Assign</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='text-center'>{autoAssignModalHeader}</h3>
                    <hr />
                    <Form onSubmit={handleSubmitAutoAssign}>
                        <div className="row">
                            <div className='col-lg-6 mb-1'>
                                <label>Available Papers</label>
                                <Form.Control type="text" placeholder="" disabled="disabled" value={autoAssignData.total_paper_count} />
                            </div>
                            <div className='col-lg-6 mb-1'>
                                <label>TPC Members</label>
                                <Form.Control type="text" placeholder="" disabled="disabled" value={autoAssignData.total_tpc_member_count} />
                            </div>
                            <div className='col-lg-12 mb-1'>
                                <label>No of Reviewers per Paper</label>
                                <Form.Control type="text" placeholder="" name='number_of_reviewers_per_paper' value={autoAssignNo.number_of_reviewers_per_paper} onChange={handleChangeAutoAssign} required />
                            </div>
                        </div>
                        <div className='text-end'>
                            <button disabled={autoAssignData.total_paper_count > 0 && autoAssignData.total_tpc_member_count > 0 && checkAutoAssignSubmit > 0 ? "" : "disabled"} className="btn btn-primary" type='submit'><span className="material-icons">group_add</span> Auto  Assign </button>
                        </div>

                        <hr />
                        <p>
                            Note:  <span className='text-danger'><b>Please confirm number of reviewers to be considered for Auto Assign.</b></span>
                        </p>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal Modal show={showTpcInviteModal} onHide={handleCloseTpcInviteModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>TPC Invitation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSubmitInvitation}>
                        <div className='mb-3'>
                            <strong>Sample Format</strong><br />
                            First_Name,Last_Name,EmailID,Country,Affiliation<br />
                            <strong>Working Example</strong><br />
                            James,Edwin,james@doman.com,USA,EasyMeet<br />
                            James,,james@doman.com,USA,EasyMeet<br />

                            <textarea className='form-control' rows={12} name="total_data" value={tpcInviteData} onChange={(e) => setTpcInviteData(e.target.value)} required></textarea>
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' />
                    </form>
                </Modal.Body>
            </Modal>
            <Modal Modal show={showSendMailModal} onHide={handleCloseSendMailModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Send Mail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSubmitSendMail}>
                        <div className='mb-3'>
                            <Form.Group className='mb-4'>
                                <label className='w-100'>Send Mail to</label>
                                <Form.Check inline type="radio" label="All Accepted Members" value='1'
                                    id="sendMailTo1"
                                    name="status" onChange={handleChangeSendMail}
                                    defaultChecked={sendMailData.status === 1 ? true : false} />
                                <Form.Check inline type="radio" label="All Non-Accepted Members" value='0'
                                    id="sendMailTo2"
                                    name="status" onChange={handleChangeSendMail}
                                    defaultChecked={sendMailData.status === 0 ? true : false} />
                            </Form.Group>
                        </div>
                        <div className='mb-3'>
                            <label>Sender</label>
                            <input type='email' className='form-control' name="sender_email" value={sendMailData.sender_email} onChange={handleChangeSendMail} required />
                        </div>
                        <div className='mb-3'>
                            <label>Subject</label>
                            <input type='text' className='form-control' name="subject" value={sendMailData.subject} onChange={handleChangeSendMail} required />
                        </div>
                        <div className='mb-3'>
                            <label>Message</label>
                            <textarea className='form-control' rows={7} name="message" value={sendMailData.message} onChange={handleChangeSendMail} required></textarea>
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TracksList