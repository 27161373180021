import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import { useLocation, useParams } from 'react-router-dom'
import { FloatingLabel, Form, Modal, Table } from 'react-bootstrap'
import FinalDecision from '../../data/FinalDecision'
import Loader from "../../components/elements/loader/Loader";
import useAjax from "../../useAjax";
import Pagination from '../../components/elements/Pagination'
import Axios from 'axios'
import Downloading from '../../components/elements/loader/Downloading'
import { useApplicationContext } from '../../contexts/ApplicationContext'

const baseURL = process.env.REACT_APP_ENDPOINT
const tableOptions = {
    'styleClass': 'custom-datatable',
    'sortBy': 'paper_sequence',
    'sortingIconDown': 'arrow_drop_down',
    'sortingIconUp': 'arrow_drop_up',
    'searchBar': true,
    'searchBarPlaceholder': 'Search',
    'pageNo': 1,
    'perPage': true,
    'perPageLabel': 'Items per page',
    'perPageDefault': 50,
    'perPageOptions': [50, 100, 200, 500],
    'pagination': true,
    'paginationOptions': {
        'styleClass': 'pagination',
        'labelLatest': '',
        'iconLatest': 'New',
        'labelOldest': '',
        'iconOldest': 'Old',
        'labelPrevious': 'Prev',
        'iconPrevious': 'Prev',
        'iconNext': '',
        'labelNext': 'Next'
    }
}
const headers = [
    {
        'label': 'PaperID',
        'key': 'paper_sequence',
        'sortable': true
    }, {
        'label': 'Download',
        'key': '',
        'sortable': false
    }, {
        'label': 'Paper Title',
        'key': 'paper_title',
        'sortable': true
    }, {
        'label': 'Authors',
        'key': 'paper_author_data',
        'sortable': false
    }, {
        'label': 'Track',
        'key': 'track_name',
        'sortable': false
    }, {
        'label': 'Reviewers',
        'key': 'paper_reviewer_data',
        'sortable': false
    }, {
        'label': 'Is reviewed',
        'key': '',
        'sortable': false
    }, {
        'label': 'Action',
        'key': '',
        'sortable': false
    },
]

const Papers = () => {

    // const detailsId = useParams()
    const prevStage = useLocation()
    const [dataStage, setDataStage] = useState(prevStage)

    const detailsId = dataStage.state || 0

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({})
    const [allPaper, setAllPaper] = useState([])
    const [showBlockPaperModal, setShowBlockPaperModal] = useState(false)
    const [dataBlockPaper, setDataBlockPaper] = useState({
        'status': 30,
        'reason': ''
    })
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [dataReview, setDataReview] = useState({
        'decision': 'No Decision',
        'final_decision_remark': ''
    })
    const [paperId, setPaperId] = useState(null)
    const [singlePaper, setSinglePaper] = useState([])
    const [singlePaperReviewData, setSinglePaperReviewData] = useState([])

    const [getSinglePaperCall, singlePaperCallSuccess] = useAjax('get');
    const [getAllPaperCall, getAllPaperCallSuccess] = useAjax('get');
    const [getPageData, getPageDataSuccess] = useAjax('get')

    const [searchText, setSearchText] = useState(null)
    const [paramSearch, setParamSearch] = useState('')
    const [paginationCount, setPaginationCount] = useState()
    const [totalCount, setTotalCount] = useState()
    const [pageNo, setPageNo] = useState(tableOptions.pageNo)
    const [perPage, setPerPage] = useState(tableOptions.perPageDefault)
    const [sortBy, setSortBy] = useState(tableOptions.sortBy)
    const [orderType, setOrderType] = useState('ASC')
    const [sortOrder, setSortOrder] = useState(tableOptions.sortingIconDown)
    const [sortActive, setSortActive] = useState('')
    const [, dispatchApplicationContext] = useApplicationContext();


    useEffect(() => {
        if (getAllPaperCallSuccess) {
            setAllPaper(getAllPaperCallSuccess.result.data)
            setTotalCount(getAllPaperCallSuccess?.result.total);
            setPaginationCount(() => {
                return Math.ceil(getAllPaperCallSuccess?.result.total / perPage)
            })
        }
    }, [getAllPaperCallSuccess])

    useEffect(() => {
        if (getPageDataSuccess) {
            setData(getPageDataSuccess.result[0])
        }
    }, [getPageDataSuccess])

    useEffect(() => {
        getPageData({ URL: `${baseURL}/site/conf/short/${detailsId.id}` })
    }, [])

    useEffect(() => {
        getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
    }, [perPage, pageNo])

    useEffect(() => {
        if (singlePaperCallSuccess) {
            setSinglePaper(singlePaperCallSuccess.result)
            setSinglePaperReviewData(singlePaperCallSuccess.result.paper_reviewer_data)
            setDataReview({
                'decision': singlePaperCallSuccess.result.decision !== null ? singlePaperCallSuccess.result.decision : 'No Decision',
                'final_decision_remark': singlePaperCallSuccess.result.final_decision_remark !== null ? singlePaperCallSuccess.result.final_decision_remark : ''
            })
        }
    }, [singlePaperCallSuccess]);

    const getSinglePaper = (id) => getSinglePaperCall({ URL: `${baseURL}/paper/${id}` })

    const handleCloseBlockPaperModal = () => setShowBlockPaperModal(false);
    const handleShowBlockPaperModal = (id) => {
        setPaperId(id)
        getSinglePaper(id)
        setShowBlockPaperModal(true)
    };

    const handleChangeBlockPaper = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataBlockPaper((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handleSubmitBlockPaper = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setDataBlockPaper((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        const confirmation = window.confirm("Are you sure to Block this Paper?")
        if (confirmation) {
            fireHandleSubmit({
                URL: `${baseURL}/paper/status-action/${paperId}`,
                payload: dataBlockPaper
            })
        }
        setShowBlockPaperModal(false)
    }

    const handleCloseReviewModal = () => setShowReviewModal(false);
    const handleShowReviewModal = (id) => {
        setPaperId(id)
        getSinglePaper(id)
        setShowReviewModal(true)
    };

    const handleChangeReview = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    const [fireHandleSubmit, handleSubmitSuccess] = useAjax('post');

    useEffect(() => {
        if (handleSubmitSuccess) {
            // window.location.reload()
            dispatchApplicationContext({ type: "serviceSuccess", payload: { statusMessage: handleSubmitSuccess?.message } })
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        }
    }, [handleSubmitSuccess]);

    const handleSubmitReview = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        const confirmation = window.confirm("Are you sure to Submit this Final Review?")
        if (confirmation) {
            fireHandleSubmit({
                URL: `${baseURL}/paper/final-review/${paperId}`,
                payload: dataReview
            })
        }
        setShowReviewModal(false)
    }
    //SendMail
    const [sendMailData, setSendMailData] = useState({
        sender_email: "",
        subject: "",
        message: ""
    })
    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const handleCloseSendMailModal = () => setShowSendMailModal(false);
    const handleShowSendMailModal = (id) => {
        setPaperId(id)
        getSinglePaper(id)
        setShowSendMailModal(true)
    };

    const handleChangeSendMail = (e) => {
        let name = e.target.name
        let value = e.target.value

        setSendMailData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }
    const handelSubmitSendMail = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setSendMailData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
        fireHandleSubmit({
            URL: `${baseURL}/paper/author-mail/${paperId}`,
            payload: sendMailData
        })
        setShowSendMailModal(false)
    }

    //Datatabel
    const handelSorting = (key) => {
        setSortBy(key)
        setSortActive(key === sortActive ? null : key)
    }

    const selectedPagination = (pageNumber) => {
        setPageNo(pageNumber)
    }

    const handleChangeSearchText = (e) => {
        const searchTerm = e.target.value
        setSearchText(searchTerm)
        if (searchText != null) {
            setParamSearch(`param_search=${e.target.value}&`)
        }

        if (searchTerm.length > 2) {
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?${paramSearch}order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        } else if (searchTerm.length === 0) {
            getAllPaperCall({ URL: `${baseURL}/paper/all/${detailsId.id}/0?order_type=${orderType}&order_by=${sortBy}&per_page=${perPage}&page=${pageNo}` })
        }
    }

    const downloadPaper = async (id) => {
        let tokenStr = localStorage.getItem('access_token')
        setIsLoading(true);
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(
                (res) => {
                    setIsLoading(false);
                    const byteCharacters = atob(res.data.result.paper_file_data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: res.data.result.paper_file_mime_type });
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.data.result.paper_sequence;

                    // Append to the document, trigger click, and remove
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Release the object URL
                    window.URL.revokeObjectURL(link.href);
                }
            ).catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <BreadCrumb title={`All Papers - ${data.acronym_title}`} from='Conference' to='Papers' back={true} />
            {isLoading && <Downloading />}

            <div className="card elevated p-3">
                <div className="d-flex justify-content-between pb-3 table-filter-control">
                    <div>
                        <input type="search" name="table-global" placeholder={tableOptions.searchBarPlaceholder} onChange={handleChangeSearchText} />
                    </div>
                    <div>
                        <select name="h" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
                            {
                                tableOptions.perPageOptions.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                            }
                        </select>
                        <span className="ml-1">{tableOptions.perPageLabel}</span>
                    </div>
                </div>
                {
                    allPaper.length > 0 ? (
                        <Table striped bordered hover className='custom-datatable' style={{ fontSize: '12px' }}>
                            {/* <thead>
                                <tr className=''>
                                    <th>PaperID</th>
                                    <th>Download</th>
                                    <th>Paper Title</th>
                                    <th>Authors</th>
                                    <th>Track</th>
                                    <th>Reviewers</th>
                                    <th>Action</th>
                                </tr>
                            </thead> */}
                            <colgroup>
                                <col style={{ "width": "100px" }} />
                                <col style={{ "width": "80px" }} />
                                <col style={{ "width": "20%" }} />
                                <col style={{ "width": "20%" }} />
                                <col style={{ "width": "20%" }} />
                                <col style={{ "width": "20%" }} />
                                <col style={{ "width": "80px" }} />
                                <col style={{ "width": "120px" }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    {
                                        headers.map((item) => {
                                            const { label, key, sortable } = item
                                            return (
                                                sortable ? <th onClick={() => handelSorting(key)} className={`sorting ${key === sortActive ? 'active' : ''}`}>
                                                    <span>{label}</span>
                                                    <span className="material-icons">
                                                        {sortOrder}
                                                    </span>
                                                </th> : <th >{label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    allPaper.length > 0 ?
                                        allPaper.map((item) => {
                                            const {
                                                id,
                                                paper_title,
                                                paper_sequence,
                                                paper_file_path,
                                                paper_author_data,
                                                track_name,
                                                paper_reviewer_data,
                                                status
                                            } = item

                                            let reviewer_confidence_counter = () => {
                                                return (
                                                    paper_reviewer_data.filter((items) => {
                                                        return items.reviewer_confidence
                                                    })
                                                )
                                            }
                                            return (

                                                <tr key={id}>
                                                    <td><span className='badge bg-primary'>{paper_sequence}</span></td>
                                                    <td>
                                                        {/* {
                                                            paper_file ? <a className='btn btn-primary btn-xs'
                                                                href={`data:${paper_file_mime_type};base64,${paper_file}`}
                                                                download={paper_sequence}><span
                                                                    className="material-icons">file_download</span> </a> : ''
                                                        } */}
                                                        {
                                                            paper_file_path ?
                                                                <button className='btn btn-primary btn-xs' onClick={() => downloadPaper(id)}><span
                                                                    className="material-icons">file_download</span></button>
                                                                : 'Paper not uploaded'
                                                        }
                                                    </td>
                                                    <td>{paper_title}</td>
                                                    <td>
                                                        <div className='maxh-7'>
                                                            {
                                                                paper_author_data.length > 0 ?
                                                                    paper_author_data.map((item) => {
                                                                        const { author_first_name, author_last_name, author_email } = item
                                                                        return (
                                                                            <div className='mb-2'>{author_first_name} {author_last_name}
                                                                                <br />{author_email}</div>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{track_name}</td>
                                                    <td>
                                                        <div className='maxh-7'>
                                                            {
                                                                paper_reviewer_data.length > 0 ?
                                                                    paper_reviewer_data.map((item) => {
                                                                        const { first_name, last_name, email, reviewer_confidence } = item
                                                                        
                                                                        if(reviewer_confidence){
                                                                            return (
                                                                                <div className='mb-1 p-1 bg-fill-light-success'>{first_name} {last_name}<br /><small>{email}</small></div>
                                                                            )
                                                                        }else{
                                                                            return (
                                                                                <div className='mb-1 p-1'>{first_name} {last_name}<br /><small>{email}</small></div>
                                                                            )
                                                                        }                                                                        
                                                                    })
                                                                    : ''
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {
                                                            reviewer_confidence_counter().length > 0 ? <span className='text-success'><strong>Yes ( {reviewer_confidence_counter().length} / {paper_reviewer_data.length} ) </strong></span> : ''
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            status === 30 ? <p>Blocked</p> : status === 25 ? <p>Withdrawn</p> : paper_file_path ?
                                                                <><button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowReviewModal(id)} title='Final Review'> <span className="material-icons text-warning">reviews</span> Final Review </button><button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowBlockPaperModal(id)} title='Block Paper'> <span className="material-icons text-warning">block</span> Block Paper </button></> : ''
                                                        }
                                                        <button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowSendMailModal(id)} title='Send Mail'> <span className="material-icons text-warning">forward_to_inbox</span> Send Mail</button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : "No record found"
                                    // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                                }
                            </tbody>
                        </Table>
                    ) : (<Loader />)
                }
                {paginationCount > 1 && (
                    <div className="d-flex justify-content-between">
                        <Pagination data={paginationCount} callback={selectedPagination} options={tableOptions.paginationOptions} />
                        <div>
                            Total <strong>{totalCount}</strong> records
                        </div>
                    </div>
                )}
            </div>
            <Modal show={showReviewModal} onHide={handleCloseReviewModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title><span
                        className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="row">
                        <div className="col-6"> */}
                    <h3>All Reviews</h3>
                    <hr />
                    <table className='table table-bordered ' style={{ fontSize: '12px' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Reviewer Evaluation</th>
                                <th>Reviewer Confidence</th>
                                <th>Reviewer Remarks</th>
                                <th>Reviewer Confidential Remarks</th>
                                <th>Reviewed At</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                singlePaperReviewData.map((item) => {
                                    const {
                                        first_name,
                                        last_name,
                                        reviewed_at,
                                        reviewer_evaluation,
                                        reviewer_confidence,
                                        reviewer_remarks,
                                        reviewer_confidential_remarks
                                    } = item
                                    return (
                                        // <Card className='p-2 elevated mb-2'>
                                        //     <div className="grid-container">
                                        //         <div className='grid-item'><b>Name</b></div>
                                        //         <div className='grid-item'>{first_name} {last_name}</div>
                                        //         <div className='grid-item'><b>Reviewer Evaluation</b></div>
                                        //         <div className='grid-item'>{reviewer_evaluation}</div>
                                        //         <div className='grid-item'><b>Reviewer Confidence</b></div>
                                        //         <div className='grid-item'>{reviewer_confidence}</div>
                                        //         <div className='grid-item'><b>Reviewer Remarks</b></div>
                                        //         <div className='grid-item'>{reviewer_remarks}</div>
                                        //         <div className='grid-item'><b>Reviewer Confidential Remarks</b></div>
                                        //         <div className='grid-item'>{reviewer_confidential_remarks}</div>
                                        //         <div className='grid-item'><b>Reviewed At</b></div>
                                        //         <div className='grid-item'>{reviewed_at}</div>
                                        //     </div>
                                        // </Card>
                                        <tr>
                                            <td>{first_name} {last_name}</td>
                                            <td>{reviewer_evaluation}</td>
                                            <td>{reviewer_confidence}</td>
                                            <td>{reviewer_remarks}</td>
                                            <td>{reviewer_confidential_remarks}</td>
                                            <td>{reviewed_at}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {/* </div>
                        <div className="col-6"> */}
                    <div className="mt-5">
                        <h3>Add Final Review</h3>
                    </div>

                    <hr />
                    <Form onSubmit={handleSubmitReview}>
                        <div className='mb-3'>
                            <FloatingLabel className='' controlId="decision" label="Decision">
                                <Form.Select name='decision' value={dataReview.decision} onChange={handleChangeReview}>
                                    {
                                        FinalDecision.map((item) => {
                                            const { value, label } = item
                                            return <option value={value}>{label}</option>
                                        })
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </div>
                        <div className='mb-3'>
                            <FloatingLabel className='required' controlId="final_decision_remark"
                                label="Final Decision Remark">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Your Final Remark"
                                    name='final_decision_remark'
                                    value={dataReview.final_decision_remark}
                                    onChange={handleChangeReview}
                                    style={{ height: '100px' }}
                                    required={true}
                                />
                            </FloatingLabel>
                        </div>

                        <div className='text-end'>
                            <button className="btn btn-clear secondary" onClick={handleCloseReviewModal}
                                type='reset'>Cancel
                            </button>
                            <button className="btn ms-3 btn-primary" type='submit'>Submit</button>
                        </div>
                    </Form>
                    {/* </div>
                    </div> */}
                </Modal.Body>
            </Modal>
            <Modal show={showBlockPaperModal} onHide={handleCloseBlockPaperModal} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title><span
                        className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitBlockPaper}>
                        <div className='mb-3'>
                            <label>Block Reason</label>
                            <textarea className='form-control' rows={5} name="reason" value={dataBlockPaper.reason} onChange={handleChangeBlockPaper} required></textarea>
                        </div>
                        <div className='text-end'>
                            <button className="btn btn-clear secondary" onClick={handleCloseBlockPaperModal}
                                type='reset'>Cancel
                            </button>
                            <button className="btn ms-3 btn-primary" type='submit'>Submit</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showSendMailModal} onHide={handleCloseSendMailModal} size='md'>
                <Modal.Header closeButton>
                    <Modal.Title><span
                        className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handelSubmitSendMail}>
                        <div className='mb-3'>
                            <h2>Send Mail to Author</h2>
                        </div>
                        <div className='mb-3'>
                            <label>Sender</label>
                            <input type='email' className='form-control' name="sender_email" value={sendMailData.sender_email} onChange={handleChangeSendMail} required />
                        </div>
                        <div className='mb-3'>
                            <label>Subject</label>
                            <input type='text' className='form-control' name="subject" value={sendMailData.subject} onChange={handleChangeSendMail} required />
                        </div>
                        <div className='mb-3'>
                            <label>Message</label>
                            <textarea className='form-control' rows={7} name="message" value={sendMailData.message} onChange={handleChangeSendMail} required></textarea>
                        </div>
                        <input type="submit" value="Submit" className='btn btn-primary' />
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Papers