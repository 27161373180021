const StatusList = [
    {
        'value': 0,
        'label': "Submitted",
    },
    {
        'value': 5,
        'label': "Five",
    }, {
        'value': 10,
        'label': "Ten",
    }, {
        'value': 15,
        'label': "Fiften",
    }, {
        'value': 18,
        'label': "No Decission",
    }, {
        'value': 20,
        'label': "Rejected/ Probably Rejected",
    }, {
        'value': 25,
        'label': "Blocked",
    }, {
        'value': 30,
        'label': "Withdrawn",
    }, {
        'value': 35,
        'label': "Accepted / Probably Accepted",
    }, {
        'value': 40,
        'label': "Fourty",
    }
]

export default StatusList