import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/elements/BreadCrumb'
import AlertCustom from '../../components/elements/AlertCustom'
import Axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Card, Form, Modal, Table } from 'react-bootstrap'
import ReviewerEvaluation from '../../data/ReviewerEvaluation'
import ReviewerConfidence from '../../data/ReviewerConfidence'
import useAjax from "../../useAjax";
import { useApplicationContext } from "../../contexts/ApplicationContext";
import Downloading from '../../components/elements/loader/Downloading'
import Loader from '../../components/elements/loader/Loader'

const baseURL = process.env.REACT_APP_ENDPOINT

const ReviewPapers = () => {
    const [isLoading, setIsLoading] = useState(false);

    let currentEmail = localStorage.getItem('email')
    let currentRole = localStorage.getItem('role')
    const navigate = useNavigate()
    // const detailsId = useParams()
    const prevStage = useLocation()

    const [dataStage, setDataStage] = useState(prevStage)

    const detailsId = dataStage.state || 0

    const [data, setData] = useState({})
    const [allPaper, setAlPaper] = useState([])

    const [showReviewModal, setShowReviewModal] = useState(false)
    const [dataReview, setDataReview] = useState({
        'reviewer_evaluation': 'Strong Accept (3)',
        'reviewer_confidence': 'Expert (5)',
        'reviewer_remarks': '',
        'reviewer_confidential_remarks': ''
    })
    const [paperId, setPaperId] = useState(null)
    const [singlePaper, setSinglePaper] = useState([])

    const [getData, getDataSuccess] = useAjax('get');
    const [getPaper, getPaperSuccess] = useAjax('get');
    const [getSinglePaper, getSinglePaperSuccess] = useAjax('get');

    const [submitReview, submitReviewSuccess] = useAjax('post');

    const [, dispatchApplicationContext] = useApplicationContext();
    useEffect(() => {
        if (getDataSuccess) {
            setData(getDataSuccess.result[0])
        }
    }, [getDataSuccess])

    useEffect(() => {
        if (getPaperSuccess) {
            setAlPaper(getPaperSuccess.result.data)
        }
    }, [getPaperSuccess])

    useEffect(() => {
        getData({
            URL: `${baseURL}/site/conf/short/${detailsId.id}`
        });
        getPaper({
            URL: `${baseURL}/paper/all/${detailsId.id}/0?per_page=5000`
        });
    }, [])


    useEffect(() => {
        if (getSinglePaperSuccess) {

            setSinglePaper(getSinglePaperSuccess.result)

            const filterPrevReviewData = () => {
                return (
                    getSinglePaperSuccess.result.paper_reviewer_data.filter((items) => {
                        return items.email === currentEmail
                    })
                )
            }
            const prevReviewData = filterPrevReviewData()
            if (prevReviewData.length > 0) {
                if (prevReviewData[0].reviewer_evaluation != null) {
                    setDataReview({
                        'reviewer_evaluation': prevReviewData[0].reviewer_evaluation,
                        'reviewer_confidence': prevReviewData[0].reviewer_confidence,
                        'reviewer_remarks': prevReviewData[0].reviewer_remarks,
                        'reviewer_confidential_remarks': prevReviewData[0].reviewer_confidential_remarks
                    })
                }
            }
        }
    }, [getSinglePaperSuccess])


    const getSinglePaperData = (id) => {
        getSinglePaper({
            URL: `${baseURL}/paper/${id}`
        })
    }

    const handleCloseReviewModal = () => {
        setDataReview(
            {
                'reviewer_evaluation': 'Strong Accept (3)',
                'reviewer_confidence': 'Expert (5)',
                'reviewer_remarks': '',
                'reviewer_confidential_remarks': ''
            }
        )
        setSinglePaper([])
        setShowReviewModal(false)
    };
    const handleShowReviewModal = (id) => {
        setPaperId(id)
        getSinglePaperData(id)

        setShowReviewModal(true)
    };

    const handleChangeReview = (e) => {
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }


    useEffect(() => {
        if (submitReviewSuccess) {
            dispatchApplicationContext({ type: "serviceSuccess", payload: { statusMessage: submitReviewSuccess?.message } })
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
    }, [submitReviewSuccess])

    const handleSubmitReview = (e) => {
        e.preventDefault()
        let name = e.target.name
        let value = e.target.value

        setDataReview((prev) => {
            return {
                ...prev, [name]: value
            }
        })

        const confirmation = window.confirm(`Are you sure to Submit this Review for : ${singlePaper.paper_sequence} - ${singlePaper.paper_title}`)
        if (confirmation) {
            submitReview({
                URL: `${baseURL}/paper/add-review/${paperId}`,
                payload: dataReview
            });
        }
    }

    const downloadPaper = async (id) => {
        let tokenStr = localStorage.getItem('access_token')
        setIsLoading(true);
        Axios.get(`${baseURL}/paper/${id}`, { headers: { "Authorization": `Bearer ${tokenStr}` } })
            .then(
                (res) => {
                    setIsLoading(false);
                    const byteCharacters = atob(res.data.result.paper_file_data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: res.data.result.paper_file_mime_type });
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = res.data.result.paper_sequence;

                    // Append to the document, trigger click, and remove
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // Release the object URL
                    window.URL.revokeObjectURL(link.href);
                }
            ).catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <BreadCrumb title={`Review Papers - ${data.acronym_title}`} from='Conference' to='Papers' back={true} />
            {isLoading && <Downloading />}

            <Card className='elevated p-4'>
                <Table striped bordered hover>
                    <thead>
                        <tr className=''>
                            <th>PaperID</th>
                            <th>Download</th>
                            <th>Paper Title</th>
                            <th>Authors</th>
                            <th>Track</th>
                            <th>Review</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allPaper.length > 0 ?
                                allPaper.map((item) => {
                                    const { id, paper_title, paper_sequence, paper_file_path, paper_author_data, track_name, paper_reviewer_data, status } = item

                                    let reviewerChecker = () => {
                                        return (
                                            paper_reviewer_data.filter((items) => {
                                                return items.email === currentEmail
                                            })
                                        )
                                    }

                                    return (
                                        (reviewerChecker().length > 0 || currentRole === "ADMIN") && paper_file_path ?
                                            <tr key={id}>
                                                <td><span className='badge bg-primary'><small>{paper_sequence}</small></span></td>
                                                <td>
                                                    {/* {
                                                        paper_file ? <a className='btn btn-primary btn-xs' href={`data:${paper_file_mime_type};base64,${paper_file}`} download={paper_sequence}><span className="material-icons">file_download</span></a> : ''
                                                    } */}
                                                    {
                                                        paper_file_path ?
                                                            <button className='btn btn-primary btn-xs' onClick={() => downloadPaper(id)}><span
                                                                className="material-icons">file_download</span></button>
                                                            : 'Paper not uploaded'
                                                    }
                                                </td>
                                                <td>{paper_title}</td>
                                                <td>
                                                    <div className='maxh-7'>

                                                        {
                                                            paper_author_data.length > 0 ?
                                                                paper_author_data.map((item) => {
                                                                    const { author_first_name, author_last_name, author_email } = item
                                                                    return (
                                                                        <div className='mb-2'>{author_first_name} {author_last_name} <br />{author_email}</div>
                                                                    )
                                                                })
                                                                : ''
                                                        }
                                                    </div>
                                                </td>
                                                <td>{track_name}</td>
                                                <td>

                                                    {
                                                        status === 30 ? 'Blocked' : status === 25 ? 'Withdrawn' : status === 18 ? 'No Decission' : status === 20 ? 'Reject/ Probably Reject' : status === 35 ? 'Accept / Probably Accept' : paper_file_path ? 
                                                            <><button className='btn btn-light elevated btn-xs btn-classic mb-2 me-1' onClick={() => handleShowReviewModal(id)} title='Review'><span className="material-icons text-warning">reviews</span> Submit Review</button></> : ''
                                                    }
                                                </td>
                                            </tr>
                                            : ''
                                    )
                                }) : (<Loader />)
                            // <div className='card bg-fill-light-redish'><div className='card-body'><span class='material-icons text-danger'>priority_high</span> No paper is submitted yet.</div></div>
                        }
                    </tbody>
                </Table>
            </Card>
            <Modal show={showReviewModal} onHide={handleCloseReviewModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title><span className='badge bg-primary'>{singlePaper.paper_sequence}</span> {singlePaper.paper_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Form onSubmit={handleSubmitReview}>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col">
                                        <Form.Group className='' controlId="" label="Reviewer Evaluation" >
                                            <Form.Label>Reviewer Evaluation</Form.Label>
                                            <Form.Select name='reviewer_evaluation' value={dataReview.reviewer_evaluation} onChange={handleChangeReview}>
                                                {
                                                    ReviewerEvaluation.map((item) => {
                                                        const { value, label } = item
                                                        return <option value={value} key={value}>{label}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <Form.Group className='' controlId="" label="Reviewer Confidence" >
                                            <Form.Label>Reviewer Confidence</Form.Label>
                                            <Form.Select name='reviewer_confidence' value={dataReview.reviewer_confidence} onChange={handleChangeReview}>
                                                {
                                                    ReviewerConfidence.map((item) => {
                                                        const { value, label } = item
                                                        return <option value={value}>{label}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <Form.Group controlId="reviewer_remarks" label="Remarks" className='required'>
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Your Remark"
                                        name='reviewer_remarks'
                                        value={dataReview.reviewer_remarks}
                                        onChange={handleChangeReview}
                                        style={{ height: '100px' }}
                                        required={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className='mb-3'>
                                <Form.Group controlId="reviewer_confidential_remarks" label="Confidential Remarks">
                                    <Form.Label>Confidential Remarks</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Any Confidential Remarks"
                                        name='reviewer_confidential_remarks'
                                        value={dataReview.reviewer_confidential_remarks}
                                        onChange={handleChangeReview}
                                        style={{ height: '100px' }}
                                    />
                                </Form.Group>
                            </div>

                            <div className='row'>
                                <div className='col text-end'>
                                    <button className="btn btn-clear secondary ms-3" onClick={handleCloseReviewModal} type='reset'>Cancel</button>
                                    <button className="btn ms-3 btn-primary" type='submit'>Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body> 
            </Modal>
        </>
    )
}

export default ReviewPapers